/* Boiler Plate Start */
.boilerBackground {
  background-color: #D0C88E;
};
.boilerContentBackground  {
  background-color: #E9E4D2;
};
.boilerTitles {
  color: #B46000;
  padding: .5rem;
  margin-left: 1rem;
  padding-top: 1rem;
};
.boilerText {
  padding: .5rem;
  margin-left: 1rem;
};
/* Boiler Plate End */

















/* Parallax Start */
.jumbotron {
  margin-bottom: 0;
}
.section {
  min-height: 600px;
  background-attachment: fixed;
  background-size: cover;
  background-position: 50% 50%;
}
/* .section p {
  font-size: 1.5rem;
  color: #fff;
  text-align: center;
  line-height: 60px;
}
.section h1 {
  color: #fff;
  font-size: 5rem;
  text-align: center;
  padding-top: 60px;
  line-height: 60px;
} */
/* Parallax End */

/* Footer */
.footerLinks {
  color: #fff;
  text-decoration: none;
}

.footerLinks:hover {
  color: #000;
}
/* Footer */


.reactivetriadsCardTopBackgroundColor {
  background-color: #000;
}

.anchorLink {
  color: "#B46000";
  text-decoration: "none";
}
.anchorLink:hover {
  opacity: 0.5;
}
